import React from 'react';
import Fade from 'react-reveal/Fade';
// import Tilt from 'react-tilt';
import Title from '../SectionTitle';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
// import AboutImg from '../gatsbyImage/AboutImg';
import ProjectImg from '../gatsbyImage/ProjectImg';
import config from '../../../config';

export default function ProjectsLayout({ title, projects }) {
  let p = config[projects];
  return (
    <div>
      <section id="projects" className="projects-section" >
        <Container className="projects-wrapper">
          <Title title={title} />
          {
            p.map(
              proj => {
                const { img, image_alt, project, title, time, explanation, link } = proj;
                return (
                  <Fade bottom duration={1000} delay={600} distance="30px">
                    <Row >
                      <Col md={5} sm={12}>
                        <div className="thumbnail rounded">
                          <ProjectImg alt={image_alt} filename={img} />
                        </div>
                      </Col>
                      <Col md={7} sm={12} className="projects-wrapper__info-text">
                        <Card>
                          <Card.Body>
                            <Card.Title className='card_title'><b>{project}</b></Card.Title>
                            <Card.Subtitle className="mb-2"><i>{title}</i></Card.Subtitle>
                            <Card.Subtitle className="mb-2 text">{time}</Card.Subtitle>
                            <Card.Text className="mb-2 text">
                              {explanation}
                            </Card.Text>
                            {
                              link &&
                              <Button className="cta-btn cta-btn--hero" href={link}>See More</Button>
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Fade>
                );
              }
            )
          }
        </Container>
      </section>
    </div>
  );
};