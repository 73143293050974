import React from 'react';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import ProjectsLayout from '../components/projects/ProjectsLayout';

export default function ProjectsPage() {
  return (
    <Layout>
      <PageTitle title="Projects" head="projecthead"/>
      <ProjectsLayout title="College Projects" projects="projects1"/>
      <ProjectsLayout title="High School Projects" projects="projects2"/>
    </Layout>
  )
}